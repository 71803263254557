// Usage:
//   drkk_decorate_page_elements(dom_element);
//
// Call on newly inserted dom-elements which might have:
//      <a href>'s so they don't do page loads.
//      other click events

(function () {
	var page_container;

	// Polyfills
	Element.prototype.remove = Element.prototype.remove || function () { this.parentNode.removeChild(this); };

	// Player
	var player;
	document.addEventListener("DOMContentLoaded", function() {
		player = new document.NaïvePlayer('audio-player');
	});

	// Middleware to only handle Cross Site Request Forgery one place
	// Monkey patch new open on global XMLHttpRequest
	var csrfToken;
	XMLHttpRequest.prototype.savedOpen = XMLHttpRequest.prototype.open;
	XMLHttpRequest.prototype.open = function(method, url, async, ...misc) {
		this.savedOpen(method, url, async);

		// Return if path !~ /profile?
		// var parsedUrl = new URL(window.location.href);
		// if (!parsedUrl.pathname.includes('/profile')) {
		// 	return;
		// }

		if (method == "POST") {
			this.setRequestHeader('X-CSRF-Token', csrfToken);
		}

		this.onloadend = function() {
			// First check if header exist before we fetch it to avoid Chrome console
			// error.
			if (this.getAllResponseHeaders().indexOf('x-csrf-token') >= 0) {
				csrfToken = this.getResponseHeader('x-csrf-token');
			}
		};
	};

	var replaced_event = new Event('replaced');
	var current_keyup;
	function replace_content(html, title) {
		page_container.innerHTML = html;
		document.title = title;

		var pops = document.querySelectorAll('.popup-window');
		for (var i = 0; i < pops.length; ++i)
			pops[i].classList.add('hidden');
		document.getElementById('overlay').style.display = 'none';

		if (current_keyup) {
			document.removeEventListener('keyup', current_keyup);
			current_keyup = null;
		}

		window.scrollTo(0,0);
		window.drkk_decorate_page_elements(page_container);
		window.dispatchEvent(replaced_event)
	}

	function update_history() {
		window.history.replaceState({
			html: page_container.innerHTML,
			title: document.title
		}, "");
	}

	const hash_length = 2;
	function set_hash(i, val) {
		var hash = window.location.hash.substr(1);
		var m = hash.split(/,/);
		var a = [];
		for (var j = 0; j < hash_length; ++j) {
			a[j] = (m[j] && m[j].match(/^\d+$/)) ? m[j] : 0;
		}
		a[i] = val;
		window.location.hash = a.join(",");
	}
	function click_elem_indexed_from_hash(i, elems) {
		var hash = window.location.hash.substr(1);
		var a = hash.split(/,/);
		if (i < a.length && a[i].match(/^\d+$/) && a[i] < elems.length) {
			elems[a[i]].click();
		}
	}

	var current_xhr;
	function switch_to(url) {
		if (!current_xhr) {
			current_xhr = new XMLHttpRequest();
			current_xhr.open("GET", url, true);
			current_xhr.responseType = "document";
			current_xhr.setRequestHeader('Content-type', 'text/html');

			current_xhr.onreadystatechange = function (e) {
				if (current_xhr.readyState == 4) {
					document.body.classList.remove('waiting');
					if (current_xhr.status == 200) {
						var response = current_xhr.response;

						var new_js;
						var new_css;
						try {
							new_js  = response.querySelector('#kk-js').getAttribute('src');
							new_css = response.querySelector('#kk-css').getAttribute('href');
						} catch(e) {
							// url did not hit anyting we recognise...
							window.location = url;
						}
						var old_js  = document.querySelector('#kk-js').getAttribute('src');
						var old_css = document.querySelector('#kk-css').getAttribute('href');
						if (new_js !== old_js || new_css !== old_css) {
							window.location = url; // world changed, so reload page
						}
						else {
							var html = response.querySelector('.js-single-page-replace').innerHTML;
							var title = response.querySelector("title").innerHTML;
							window.history.pushState({"html": html, "title": title}, "", url);
							replace_content(html, title);
						}
					}
					else {
						window.location = url; // just send error to browser
					}
					try {
						window.gemius_stat.ajax_hit();
						window.gallup_stat.ajax_hit();
					}
					catch(e) {
					};
					current_xhr = null;
				}
			};
			current_xhr.send();
			document.body.classList.add('waiting');
		}
	}

	/**
	 * Set alert message on page
	 */
	function set_message(message) {
		let m = document.querySelector('.alert-mes .page-container');
		let d = m.parentElement;
		m.innerHTML = message;
		d.classList.remove('hidden');

		setTimeout(function () {
			d.classList.add('hidden');
		}, 6000);
	}

	function clear_search() {
		// Remove and clear search box
		var elems = document.getElementsByClassName('search-top');
		for(var j = 0; j < elems.length; ++j) {
			elems[j].classList.remove('active');
		}

		document.getElementById('overlay').style.display = 'none';
		document.getElementById('autocomplete-input').value = '';
		document.getElementById('autocomplete-output').classList.remove('active');
		document.getElementById('autocomplete-output').innerHTML = '';
	}
	window.drkk_decorate_page_elements = function(top) {
		var i;
		let as = top.getElementsByTagName("a");
		for (i = 0; i < as.length; i++) {
			var elem = as[i];
			var target = elem.target || "";
			if (elem.classList.contains('social-login-facebook') || elem.classList.contains('social-login-google') ||
				elem.classList.contains('social-login-twitter') || elem.classList.contains('social-login-soundcloud') ||
				elem.hasAttribute('download')
			   )
				continue;

			if (elem.href && elem.href.match("^" + url_prefix) && target != '_blank') {
				elem.addEventListener('click', function(e) {
					if (e.which == 1) { // only left click
						e.preventDefault();
						switch_to(this.href);
					}
				});
			}
		}

        const initRadioHandlers = () => {
            const handlers = top.querySelectorAll('.handle-new');

            for (i = 0; i < handlers.length; ++i) {
                handlers[i].addEventListener('click', function(e) {
                    const id = this.getAttribute('id');
                    const comment = id.replace(/\w+/, 'comment');
                    const save = id.replace(/\w+/, 'save');

                    if (this.value.match(/recommend|reject/)) {
                        document.getElementById(comment).classList.remove('hidden');
                    }
                    else {
                        document.getElementById(comment).classList.add('hidden');
                    }
                    document.getElementById(save).classList.remove('hidden');
                });
            }
        }

        // Called on profile page load
        initRadioHandlers()

		var togglers = top.querySelectorAll('.toggle-item');
		for (i = 0; i < togglers.length; ++i) {
			let idx = i;
			togglers[i].addEventListener('click', function(e) {
				e.preventDefault();
				var k, me = this.getAttribute('id').replace(/.*-/, '');
				var tis = document.getElementsByClassName('toggle-item');
				for (k = 0; k < tis.length; ++k) {
					tis[k].classList.remove('active');
				}
				this.classList.add("active");

				var lts = document.getElementsByClassName('track-toggler');
				for (k = 0; k < lts.length; ++k) {
					lts[k].classList.add('hidden');
				}
				document.getElementById('track-toggler-' + me).classList.remove('hidden');

				var loadmorelink = this.parentElement.parentElement.getElementsByClassName('load-more-link')[0];
				if (loadmorelink)
					loadmorelink.setAttribute('id', 'do-track-toggler-' + me);
				set_hash(1,idx);
				update_history();
			});
		}
		click_elem_indexed_from_hash(1, togglers);

		var showmores = top.getElementsByClassName('show-more-link');
		for (i = 0; i < showmores.length; ++i) {
			showmores[i].addEventListener('click', function(e) {
				e.preventDefault();
				var count;
				var list_id = this.getAttribute('id').replace(/do-(\d+)-/, function(match, c) { count = c; return ""; });
				var list = document.getElementById(list_id);
				var hiddens = list.querySelectorAll('.item.hidden, .embedded-item.hidden');
				for (var j = 0, max=Math.min(hiddens.length,count); j < max; ++j) {
					hiddens[j].classList.remove("hidden");
				}
				if (hiddens.length <= count) {
					this.classList.add("hidden");
				}
			});
		}

		var downloaders = top.querySelectorAll('a.download');
		for (i = 0; i < downloaders.length; ++i) {
			downloaders[i].addEventListener('click', function(e) {
				let message = downloaders[i].getAttribute('data-message');
				set_message(message);
			});
		}

		var loadmores = top.getElementsByClassName('load-more-link');
		for (i = 0; i < loadmores.length; ++i) {
			loadmores[i].addEventListener('click', function(e) {
				e.preventDefault();
				var container_id = this.getAttribute('id').replace(/do-/, '');
				let container = document.getElementById(container_id);

				var base   = container.getAttribute('data-basehref');
				var offset = container.children.length;
				var limit  = 8;

				var url = base + '/' + offset + '/' + limit;

				let xhr = new XMLHttpRequest();
				xhr.open("GET", url, true);
				xhr.responseType = "document";
				xhr.setRequestHeader('Content-type', 'text/html');

				xhr.onreadystatechange = function (e) {
					if (xhr.readyState == 4 && xhr.status == 200) {
						var tracks = xhr.response.getElementsByClassName('track-list-container')[0].children;
						while( tracks.length ) {
							window.drkk_decorate_page_elements(tracks[0]);
							container.appendChild(tracks[0]);
						}
						update_history();
					}
				};

				xhr.send();
			});
		}

        top.querySelectorAll('.search-menu').forEach(searchElement => {
            const parent = searchElement.closest('.search-menu-parent');

            // Add listener only for a visible search element (desktop or mobile)
            if (parent && window.getComputedStyle(parent).display !== 'none') {
                searchElement.addEventListener('click', function (e) {
                    e.preventDefault();

                    var elems = top.getElementsByClassName('search-top');
                    for (var j = 0; j < elems.length; ++j) {
                        if (elems[j].classList.contains('active')) {
                            elems[j].classList.remove('active');
                            clear_search();
                        } else {
                            document.getElementById('overlay').style.display = "block";
                            elems[j].classList.add('active');
                            elems[j].querySelector('#autocomplete-input').focus();
                        }
                    }
                });
            }
        })

		var icons = top.getElementsByClassName('btn-favorite');
		for (i = 0; i < icons.length; ++i) {
			icons[i].addEventListener('click', function (e) {
				e.preventDefault();
				let elem = this;
				let xhr = new XMLHttpRequest();
				var params = 'id=' + this.getAttribute('data-id');
				xhr.open("POST", rel_prefix + '/track/togglefav', true);
				xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

				xhr.onreadystatechange = function (e) {
					if (xhr.readyState == 4 && xhr.status == 200) {
						if (xhr.response == 'on') {
							elem.classList.add('favorite');
						}
						else if (xhr.response == 'off') {
							elem.classList.remove('favorite');
						}
						else {
							document.getElementById('popup-favorite').classList.remove('hidden');
							document.getElementById('overlay').style.display = "block";
						}
					}
				};

				xhr.send(params);
			});
		}

		var embedders = top.querySelectorAll('.embed');
		for (i = 0; i < embedders.length; ++i) {
			let embedder = embedders[i];
			embedder.addEventListener('click', function(e) {
				var id = embedder.dataset.id;
				var type = embedder.dataset.type;
				var popup = document.getElementById('popup-embed');
				var text = popup.querySelector('textarea');
				text.innerHTML = text.innerHTML.replace(/[^\/]+\/embed\/\d+/, type + "/embed/" + id);
				popup.classList.remove('hidden');
				document.getElementById('overlay').style.display = "block";
			});
		}

		var closers = top.querySelectorAll('.popup-window .btn-close');
		for (i = 0; i < closers.length; ++i) {
			closers[i].addEventListener('click', function(e) {
				var pops = document.querySelectorAll('.popup-window');
				for (var i = 0; i < pops.length; ++i)
					pops[i].classList.add('hidden');
				document.getElementById('overlay').style.display = "none";
			});
		}

		function add_errors(form,errors) {
			var topelm;
			for (let j = 0; j < errors.length; ++j) {
				var err = errors[j];
				var inputs = form.querySelectorAll('textarea[name="' + err.param + '"], input[name="' + err.param + '"]');
				if (inputs.length > 0) {
					let input = inputs[0];
					if (err.index > 0)
						input = inputs[err.index];
					if (!topelm) topelm = input;
					if (input.getBoundingClientRect().top < topelm.getBoundingClientRect().top)
						topelm = input;
					var target = [ input ];
					if (err.param == 'filename-holder') {
						target = [ input.previousElementSibling.querySelector('input') ];
					}
					if (input.dataset.target) {
						input = form.querySelector(input.dataset.target);
						target = inputs;
					}
					let div = document.createElement("div");
					div.className = 'message js-error';
					div.innerHTML = err.msg;
					input.parentElement.insertBefore(div, input);
					input.classList.add('error');
					let remove_error = function() {
						setTimeout(function() {
							input.classList.remove('error');
							try {
								div.parentNode.removeChild(div);
							}
							catch(e) {}
						}, 2500);
					};
					for (var k = 0; k < target.length; ++k) {
						target[k].addEventListener('change', remove_error, { once: true });
						target[k].addEventListener('focus',  remove_error, { once: true });
					}
				}
			}
			if (topelm) {
				topelm.scrollIntoView({ behavior: 'smooth' });
			}
		}
		function remove_errors(form) {
			var j, errs = form.querySelectorAll('.js-error');
			for (j = 0; j < errs.length; ++j)
				errs[j].parentNode.removeChild(errs[j]);

			errs = form.querySelectorAll('.error');
			for (j = 0; j < errs.length; ++j)
				errs[j].classList.remove('error');
		}

		var ajaxforms = top.querySelectorAll('form.js-ajax-form');
		for (i = 0; i < ajaxforms.length; ++i) {
			let form = ajaxforms[i];
			form.addEventListener('submit', function(e) {
				e.preventDefault();
				var data = new FormData(form);
				var url = form.getAttribute('action');
				let xhr = new XMLHttpRequest();
				xhr.open("POST", url, true);
				xhr.responseType = "json";
				xhr.onreadystatechange = function(e) {
					if (xhr.readyState == 4) {
                        document.body.classList.remove('waiting');
						if (xhr.status == 200) {
							let data = xhr.response;
							if (typeof data === 'string') { // argh, IE
								try {
									data = JSON.parse(data);
								} catch (e) {
									data = {};
								}
							}

							if (data.message) {
								set_message(data.message);
							}

							remove_errors(form);
							if (data.error) {
								add_errors(form, data.error);
							}
							else if (data.goto) {
								switch_to(data.goto);
							}
						}
					}
				};
				xhr.send(data);
				document.body.classList.add('waiting');
			});

			if(!csrfToken && form.getAttribute('action').match(/profile/)) {
				let url = form.getAttribute('action');
				url = url.replace(/\/profile.+$/, "/profile/ping");
				console.log(url);
				let xhr = new XMLHttpRequest();
				xhr.open("GET", url);
				//xhr.onreadystatechange = function(e) {
				//	if (xhr.readyState == 4) {
				//		console.log("pong");
				//	}
				//}
				xhr.send();
			}
		}

        const initTrackForms = () => {
            var trackforms = top.querySelectorAll('form.admin-track');
            for (i = 0; i < trackforms.length; ++i) {
                let trackform = trackforms[i];
                trackform.addEventListener('submit', function(e) {
                    e.preventDefault();
                    var data = new FormData(trackform);
                    var data = new FormData(trackform);
                    var url = trackform.getAttribute('action');
                    let xhr = new XMLHttpRequest();
                    xhr.open("POST", url, true);
                    xhr.responseType = "text";
                    xhr.onreadystatechange = function (e) {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            var li = trackform.parentElement;
                            var state = trackform.elements.namedItem('state').value;

                            if (!li.querySelector('.radio-approve').classList.contains('hidden')) {
                                // it has the approve button, so in first column
                                li.parentElement.removeChild(li);
                                if (state == 'approve' || state == 'recommend') {
                                    li.querySelector('.radio-kanon').classList.remove('hidden');

                                    var list = document.querySelector('.approved-tracks');
                                    list.insertBefore(li, list.firstChild);
                                }
                            }
                            else if (state == 'reject') {
                                li.parentElement.removeChild(li);
                            }

                            if (state =='recommend') {
                                li.querySelector('.track-recommended-label').classList.remove('hidden');
                            }
                            if (state =='kanon') {
                                li.querySelector('.track-kanon-label').classList.remove('hidden');
                                li.querySelector('.radio-kanon').classList.add('hidden');
                            }
                            if (state == 'approve' || state == 'recommend' || state == 'kanon') {
                                li.querySelector('.radio-approve').classList.add('hidden');
                                li.querySelector('.track-textarea').classList.add('hidden');
                                li.querySelector('.track-textarea textarea').innerHTML = '';
                                li.querySelector('.admin-action-btn').classList.add('hidden');
                            }

                            var radios = trackform.state;
                            for(var i = 0; i < radios.length; i++)
                                radios[i].checked = false;
                        }
                    };
                    xhr.send(data);
                });
            }
        }

        // Called on profile page load
		initTrackForms()

		var delimgbuttons = top.querySelectorAll('.btn-delete-image');
		for (i = 0; i < delimgbuttons.length; ++i) {
			let button = delimgbuttons[i];
			let parent = button.parentElement;
			button.addEventListener('click', function(e) {
				e.preventDefault();
				parent.querySelector('img').src = rel_prefix + "/images/default-user.png";
				parent.querySelector('input[name="imageurl"]').value = "";
				parent.querySelector('input[type="text"]').value = "";
			});
		}

		var deltrackbuttons = top.querySelectorAll('.track-delete');
		for (i = 0; i < deltrackbuttons.length; ++i) {
			let button = deltrackbuttons[i];
			button.addEventListener('click', function(e) {
				e.preventDefault();
				let id = button.getAttribute('data-id');
				let msg = button.getAttribute('data-message');
				if (confirm(msg)) {
					let params = 'trackid=' + id;
					let xhr = new XMLHttpRequest();
					xhr.open("POST", rel_prefix + '/profile/deltrack', true);
					xhr.responseType = "text";
					xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

					xhr.onreadystatechange = function (e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
							var div1 = document.querySelector('#edit-track-' + id);
							var div2 = document.querySelector('#show-track-' + id);
							div1.parentElement.removeChild(div1);
							div2.parentElement.removeChild(div2);
							if(limiter) limiter();
						}
					};

					xhr.send(params);
				}
			});
		}

		var delartistbuttons = top.querySelectorAll('.artist-delete');
		for (i = 0; i < delartistbuttons.length; ++i) {
			let button = delartistbuttons[i];
            let id = button.getAttribute('data-id');

			button.addEventListener('click', function(e) {
				e.preventDefault();
				let msg = button.getAttribute('data-message');
				if (confirm(msg)) {
					let params = 'artistid=' + id;
					let xhr = new XMLHttpRequest();

					xhr.open("POST", rel_prefix + '/profile/delete-artist', true);
					xhr.responseType = "text";
					xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

					xhr.onreadystatechange = function (e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
                            const isArtistPage = button.getAttribute('data-is-artist-page');

                            if (isArtistPage) {
                                window.location.replace(window.location.origin)
                            } else {
                                var div1 = document.querySelector('#edit-artist-' + id);
                                var div2 = document.querySelector('#show-artist-' + id);
                                div1.parentElement.removeChild(div1);
                                div2.parentElement.removeChild(div2);
                            }
						}
					};

					xhr.send(params);
				}
			});

            // set X-CSRF-Token to delete artist
            if(!csrfToken) {
				let url = '/artist/' + id + '/ping'
				let xhr = new XMLHttpRequest();
				xhr.open("GET", url);
				xhr.send();
			}
		}

		let limiter;
		var delbuttons = top.querySelectorAll('.expert-list .btn-delete');
		for (i = 0; i < delbuttons.length; ++i) {
			let button = delbuttons[i];
			button.addEventListener('click', function(e) {
				var name = button.parentElement.querySelectorAll('.name')[0].textContent;
				let msg = button.getAttribute('data-message');
				if (confirm(msg)) {
					var userid = button.getAttribute('data-userid');
					var params = 'roleid=1&userid=' + userid;

					let xhr = new XMLHttpRequest();
					xhr.open("POST", rel_prefix + '/profile/setuserrole', true);
					xhr.responseType = "text";
					xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

					xhr.onreadystatechange = function (e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
							var li = button;
							while (!li.classList.contains('item')) {
								li = li.parentElement;
							}
							li.parentElement.removeChild(li);
						}
					};

					xhr.send(params);
				}
			});
		}

		function make_searchable(baseurl,input,output,empty,decorator,onclose) {
			// Use an attribute as guard to prevent that elements are used for multiple searches...
			if (input.getAttribute('searchable'))
				return;

			input.setAttribute('searchable', 'true');
			input.addEventListener('keyup', function(e) {
				if (e.which == 27) {
					onclose();
					return;
				}

				if (input.value) {
					var url = baseurl + '?q=' + input.value;

					let xhr = new XMLHttpRequest();
					xhr.open("GET", url, true);
					xhr.responseType = "document";
					xhr.setRequestHeader('Content-type', 'text/html');

					xhr.onreadystatechange = function (e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
							var result = xhr.response.getElementById('autocomplete-output');

							output.innerHTML = result.innerHTML;
							decorator(output);

							if (output.children.length) {
								empty.classList.remove('active');
								output.classList.add("active");
							} else {
								empty.classList.add('active');
								output.classList.remove('active');
							}
						}
					};

					xhr.send();
				} else {
					empty.classList.remove('active');
					output.classList.remove('active');
				}
			});
		}

		if(document.querySelector('#search-top')) {
			make_searchable(
				rel_prefix + '/autocomplete',
				document.getElementById('autocomplete-input'),
				document.getElementById('autocomplete-output'),
				document.getElementById('autocomplete-empty'),
				window.drkk_decorate_page_elements,
				clear_search
			);
		}

		if(document.querySelector('#kanon-search')) {
			make_searchable(
				rel_prefix + '/autocomplete',
				document.getElementById('autocomplete-input'),
				document.getElementById('autocomplete-output'),
				document.getElementById('autocomplete-empty'),
				window.drkk_decorate_page_elements,
				clear_search
			);
		}

		var usersearches = top.getElementsByClassName('user-search');
		for (i = 0; i < usersearches.length; ++i) {
			let search = usersearches[i];

			let input  = search.querySelector('input');
			let output = search.querySelector('.autocomplete');
			let empty  = search.querySelector('.autocomplete-empty');
			let onclose = function (e) {
				input.value = '';
				output.classList.remove('active');
				empty.classList.remove('active');
			};

			make_searchable(
				rel_prefix + '/autocomplete/profile',
				input, output, empty,
				function (top) {
					let buttons = top.getElementsByTagName('button');
					for (var i = 0; i < buttons.length; i++) {
						let button = buttons[i];
						button.addEventListener('click', function(e) {
							let id = e.currentTarget.getAttribute('data-userid');
							let roleid = input.getAttribute('data-roleid');
							let listid = input.getAttribute('data-target');
							let params = 'userid=' + id + '&roleid=' + roleid;

							let xhr = new XMLHttpRequest();
							xhr.open("POST", rel_prefix + '/profile/setuserrole', true);
							xhr.responseType = "text";
							xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

							xhr.onreadystatechange = function (e) {
								if (xhr.readyState == 4 && xhr.status == 200) {
									button.classList.remove('dr-icon-add');
									button.classList.add('dr-icon-close');
									button.classList.add('btn-delete');
									var li = document.createElement("li");
									li.className = 'item';
									li.innerHTML = button.parentElement.innerHTML;
									var list = document.getElementById(listid);
									list.insertBefore(li, list.firstChild);
									window.drkk_decorate_page_elements(li);
									onclose();
								}
							};

							xhr.send(params);
						 });
					}
				},
				onclose
			);
		}

		var singlesearches = top.getElementsByClassName('track-search-one');
		for (i = 0; i < singlesearches.length; ++i) {
			let search = singlesearches[i];
			let adm = search.classList.contains('track-adm');
			let rec = search.classList.contains('track-rec');
			let art = search.classList.contains('track-art');
			let remote = search.classList.contains('track-remote');
			let tracklist = search.querySelector('.track-list');

			let input  = search.querySelector('input');
			let output = search.querySelector('.autocomplete');
			let empty  = search.querySelector('.autocomplete-empty');
			let onclose = function (e) {
				input.value = '';
				output.classList.remove('active');
				empty.classList.remove('active');
			};

			var url = rel_prefix + '/autocomplete/track';
			if (adm) url = url + "adm";
			if (rec) url = url + "rec";
			if (art) url = url + "art";
			if (remote) url = url + "remote";
			make_searchable(
				url, input, output, empty,
				function (top) {
					let as = top.getElementsByTagName("a");
					for (var i = 0; i < as.length; i++) {
						let elem = as[i];
						elem.addEventListener('click', function (e) {
							e.preventDefault();

							let id = e.currentTarget.getAttribute('data-track-id');
							if (adm || rec || art) {
								var div = document.getElementById("extra-track-" + id);
								let targetid = input.getAttribute('data-target');
								var list = document.getElementById(targetid);
								list.innerHTML = div.innerHTML;
								output.innerHTML = "";
								if (rec || art) {
									list.previousElementSibling.classList.add('hidden');
									list.classList.remove('hidden');
								}
								window.drkk_decorate_page_elements(list);
							}
							onclose();
						});
					}
				 },
				 onclose
			);
		}
		var trackforminfos = top.querySelectorAll('.track-form-info');
		for (i = 0; i < trackforminfos.length; ++i) {
			let trackforminfo = trackforminfos[i];
			let button = trackforminfo.querySelector('.btn-delete');
			if (button) button.addEventListener('click', function (e) {
				e.preventDefault();
				trackforminfo.parentElement.classList.add('hidden');
				trackforminfo.parentElement.previousElementSibling.classList.remove('hidden');
				let input = trackforminfo.parentElement.querySelector("input[name=trackid]");
				if(input)
					input.value = "";
			});
		}

		var searches = top.getElementsByClassName('track-search-multi');
		for (i = 0; i < searches.length; ++i) {
			let search = searches[i];
			let tracklist = search.querySelector('.track-list');

			let input  = search.querySelector('input');
			let output = search.querySelector('.autocomplete');
			let empty  = search.querySelector('.autocomplete-empty');
			let onclose = function (e) {
				input.value = '';
				output.classList.remove('active');
				empty.classList.remove('active');
			};

			make_searchable(
				rel_prefix + '/autocomplete/track',
				input, output, empty,
				function (top) {
					let as = top.getElementsByTagName("a");
					for (var i = 0; i < as.length; i++) {
						let elem = as[i];
						elem.addEventListener('click', function (e) {
							e.preventDefault();

							let id = e.currentTarget.getAttribute('data-track-id');

							let xhr = new XMLHttpRequest();
							xhr.open("GET", rel_prefix + '/render/track/select-multi-item/' + id, true);
							xhr.responseType = "document";
							xhr.setRequestHeader('Content-type', 'text/html');

							xhr.onreadystatechange = function (e) {
								if (xhr.readyState == 4 && xhr.status == 200) {
									let item = xhr.response.querySelector('body > li');
									item.setAttribute('draggable', 'true');
									item.querySelector('.btn-delete').addEventListener('click', function (e) { item.remove() });

									var imgs = item.querySelectorAll('img');
									for (var i = 0; i < imgs.length; ++i) {
										imgs[i].setAttribute('draggable', 'false');
									}
									tracklist.appendChild(xhr.response.querySelector('body > li'));
									onclose();
								}
							};

							xhr.send();
						 });
					}
				 },
				 onclose
			);
		}


		function make_draggable(top, onchange) {
			let msg = top.getAttribute('data-message');
			let placeholder = document.createElement('li'); placeholder.classList.add('drop-target'); placeholder.innerHTML = msg;

			let dragged;
			let dropper = function (e) {
				if (dragged === undefined)
					return;

				e.preventDefault();
				var target = e.currentTarget;
				var parent = target.parentElement;

				parent.insertBefore(dragged.item, target);
				if (onchange)
					onchange();
			};

			placeholder.addEventListener('dragover', function (e) { e.preventDefault() });
			placeholder.addEventListener('drop', dropper);

			// dragstart, dragover, and dragend is handled on the LI element
			top.addEventListener('dragstart', function (e) {
				if (e.target.tagName != 'LI')
					return true;

				dragged = {
					top: top,
					item: e.target
				}

				e.dataTransfer.setData('text', '<placeholder>');
				e.stopPropagation();
			});
			top.addEventListener('dragend', function (e) {
				dragged = undefined;
				placeholder.style.display = 'none';
			});
			top.addEventListener('dragover', function (e) {
				if (dragged === undefined)
					return;

				var parent = dragged.top;
				var target = e.target;
				while(target.parentNode != parent && target.parentNode) {
					target = target.parentNode;
				}

				if (target.tagName != 'LI' || target.classList.contains('drop-target'))
					return true;


				var rect = target.getBoundingClientRect();
				var isFirstHalf = e.clientY < rect.top + rect.height / 2;

				parent.insertBefore(placeholder, isFirstHalf ? target : target.nextElementSibling);
				placeholder.style.display = 'block';

				e.stopPropagation();
			});

			var items = top.querySelectorAll('li.item,li.drag-item');
			for (var i = 0; i < items.length; ++i) {
				items[i].setAttribute('draggable', 'true');
			}

			var items = top.querySelectorAll('img');
			for (var i = 0; i < items.length; ++i) {
				items[i].setAttribute('draggable', 'false');
			}
		}

        // Admin page -> Artists page
		var elems = top.querySelectorAll('.track-order');
		for (i = 0; i < elems.length; ++i) {
			let form = elems[i];
			make_draggable(
				form.querySelector('ul'),
				function() {
					var values = [ form.querySelector('input[name=artistid]').value ];
					var inputs = form.querySelectorAll('input[name=track]');

					for (var i = 0; i < inputs.length; ++i ) {
						values.push(inputs[i].value);
					}

					let xhr = new XMLHttpRequest();
					xhr.open("POST", form.getAttribute('action'), true);
					xhr.responseType = "document";
					xhr.setRequestHeader('Content-type', 'application/json');

					xhr.onreadystatechange = function (e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
							1;
						}
					};

					xhr.send(JSON.stringify(values));
				}
			);
		}

        // Admin page - drag&drop on tracks inside playlist
		const draggable = top.querySelectorAll('ul.draggable-list');
		for (var i = 0; i < draggable.length; ++i) {
			make_draggable(draggable[i]);
		}

		// Admin page - drag&drop on playlists
		var elem = top.querySelector('#playlist-order');
		if (elem) {
			let form = elem;
			make_draggable(
				elem.querySelector('ul'),
				function() {
					var values = [];
					var inputs = form.querySelectorAll('input[name=playlist]');
					for (var i = 0; i < inputs.length; ++i ) {
						values.push(inputs[i].value);
					}

					let xhr = new XMLHttpRequest();
					xhr.open("POST", form.getAttribute('action'), true);
					xhr.responseType = "document";
					xhr.setRequestHeader('Content-type', 'application/json');

					xhr.onreadystatechange = function (e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
							1;
						}
					};

					xhr.send(JSON.stringify(values));

				}
			);
		}

		var elems = document.querySelectorAll('.button-link');
		for (var i = 0; i < elems.length; ++i) {
			let elem = elems[i];
			elem.addEventListener('click', function (e) {
				var href = this.getAttribute('data-href');
				if (href) document.location.href = rel_prefix + href;
			});
		}

		var elem = top.querySelector('#new-playlist');
		if (elem) {
			elem.addEventListener('click', function (e) {
				var url = rel_prefix + '/profile/playlist/create';

				let xhr = new XMLHttpRequest();
				xhr.open("GET", url, true);
				xhr.responseType = "document";
				xhr.setRequestHeader('Content-type', 'text/html');

				xhr.onreadystatechange = function (e) {
					if (xhr.readyState == 4 && xhr.status == 200) {
						var editor = document.getElementById('playlist-editor');
						var new_editor = xhr.response.getElementById('playlist-editor');

						editor.innerHTML = new_editor.innerHTML;
						window.drkk_decorate_page_elements(editor);
						editor.scrollIntoView({ behavior: 'smooth' });
					}
				};

				xhr.send();
			});
		}

        const getFullPlaylist = (id, order) => {
            const url = rel_prefix + '/profile/playlist/edit/' + id + '/' + order;

            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "document";
            xhr.setRequestHeader('Content-type', 'text/html');

            xhr.onreadystatechange = function (e) {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    var editor = document.getElementById('playlist-editor');
                    var new_editor = xhr.response.getElementById('playlist-editor');

                    editor.innerHTML = new_editor.innerHTML;
                    window.drkk_decorate_page_elements(editor);

                    var tracks = editor.querySelectorAll("ul.track-list > li");
                    for(var i = 0; i < tracks.length ; ++i) {
                        let track = tracks[i];
                        track.querySelector('.btn-delete').addEventListener('click', function (e) {
                            e.preventDefault();
                            track.remove();
                        });
                    }

                    editor.scrollIntoView({ behavior: 'smooth' });
                }
            };

            xhr.send();
        }

		var playlists = top.querySelectorAll('ul.playlist-list > li');
		for (var i = 0; i < playlists.length; ++i) {
			let list = playlists[i];
			let id   = list.querySelector('input').value;

			list.querySelector(".btn-settings").addEventListener('click', function (e) {
				e.preventDefault();

				getFullPlaylist(id, 'default')
			});

            // Delete playlist
			list.querySelector(".btn-delete").addEventListener('click', function (e) {
				e.preventDefault();
				var editor = document.getElementById('playlist-editor');
				if (editor) {
					var form = editor.querySelector('form');
					if (form && form.getAttribute('data-playlist-id') == id)
						editor.innerHTML = '';
				}

				var url = rel_prefix + '/profile/playlist/delete/' + id;

				let xhr = new XMLHttpRequest();
				xhr.open("POST", url, true);
				xhr.responseType = "document";
				xhr.setRequestHeader('Content-type', 'text/html');

				xhr.onreadystatechange = function (e) {
					if (xhr.readyState == 4 && xhr.status == 200) {
						list.remove();
					}
				};

				xhr.send();
			});
		}

        // Sort playlist's tracks
        const playlistSortRadiosContainer = top.querySelector('.playlist-sort-tracks')
        if (playlistSortRadiosContainer) {
            const playlistOrder = playlistSortRadiosContainer.dataset.order
            const inputs = playlistSortRadiosContainer.querySelectorAll('input')

            for (let i = 0; i < inputs.length; i++) {
                const input = inputs[i];
                const inputOrder = input.dataset.order
                const playlistId = top.querySelector('form#playlist-create').dataset.playlistId

                if (playlistOrder === inputOrder) {
                    input.checked = true
                } else {
                    input.checked = false
                }

                input.addEventListener('click', function (e) {
                    e.preventDefault();

                    getFullPlaylist(playlistId, inputOrder)
                })
            }
        }

		var blocktogglers = top.getElementsByClassName('block-toggler');
		for (i = 0; i < blocktogglers.length; ++i) {
			let toggler = blocktogglers[i];

			if(toggler.dataset.maxItems) {
				let onner = document.querySelector(toggler.getAttribute('data-on'));
				let add;
				if(onner) {
					onner.classList.forEach(function(x) { if(x.match(/^add\-/)) add = x; });
					if(add && toggler.dataset.parentItem) {
						limiter = function() {
							let items = Array.from(document.querySelectorAll('.'+add)).filter(
								function(x) { return toggler.dataset.parentItem === x.dataset.parentItem  }
							);
							if(items.length < toggler.dataset.maxItems) {
								toggler.classList.remove('hidden');
							} else {
								toggler.classList.add('hidden');
							}
						};
						limiter();
					}
				}
 			}

			toggler.addEventListener('click', function (e) {
				e.preventDefault();
				let onners = document.querySelectorAll(toggler.getAttribute('data-on'));
				let offers = document.querySelectorAll(toggler.getAttribute('data-off'));
				let clearers = document.querySelectorAll(toggler.getAttribute('data-clear'));
				let clickers = document.querySelectorAll(toggler.getAttribute('data-click'));
				let reset_to_on = document.querySelectorAll('.block-toggler-on');
				let reset_to_off = document.querySelectorAll('.block-toggler-off');

				if(limiter)
					limiter();

				var j;
				for (j = 0; j < reset_to_on.length; ++j) {
					reset_to_on[j].classList.remove('hidden');
				}
				for (j = 0; j < reset_to_off.length; ++j) {
					reset_to_off[j].classList.add('hidden');
				}
				for (j = 0; j < offers.length; ++j) {
					offers[j].classList.add('hidden');
				}
				for (j = 0; j < onners.length; ++j) {
					onners[j].classList.remove('hidden');
					onners[j].scrollIntoView({ behavior: 'smooth' });
				}
				for (j = 0; j < clearers.length; ++j) {
					clearers[j].value = '';
				}
				for (j = 0; j < clickers.length; ++j) {
					clickers[j].click();
				}
			});
		}

		var battlenavs = top.querySelectorAll('.battle-nav');
		for (i = 0; i < battlenavs.length; ++i) {
			let bn = battlenavs[i];
			bn.addEventListener('click', function(e) {
				e.preventDefault();
				var off = bn.getAttribute('data-off');
				var on  = bn.getAttribute('data-on');
				if (on)
					document.getElementById(on).classList.remove('hidden');
				if (off)
					document.getElementById(off).classList.add('hidden');
			});
		}

		var duplicators = top.getElementsByClassName('duplicate-button');
		for (i = 0; i < duplicators.length; ++i) {
			let dup = duplicators[i];
			dup.addEventListener('click', function (e) {
				e.preventDefault();
				var input = dup.parentElement.querySelector('input').cloneNode(false);
				input.value = '';
				input.classList.remove('error');
				dup.parentElement.insertBefore(input, dup);
			});
		}

		var instrumentals = top.querySelectorAll('.instrumental input[type="checkbox"]');
		for (i = 0; i < instrumentals.length; ++i) {
			let inst = instrumentals[i];
			inst.addEventListener('change', function (e) {
				let tid = top.querySelector('#' + inst.dataset.target);
				let elms = tid.querySelectorAll('button,input');
				elms.forEach(function (e) {
					if (inst.checked) {
						tid.classList.add('disabled');
						e.setAttribute('disabled', 'disabled');
					}
					else {
						tid.classList.remove('disabled');
						e.removeAttribute('disabled');
					}
				});
			});
		}

        let articles = document.querySelectorAll('.article-lookup-one ');
		for (i = 0; i < articles.length; ++i) {
        	let article = articles[i];

            let button = article.querySelector('.btn-delete');
            if(button) {
                button.addEventListener('click', function (e) {
                    e.preventDefault();
                    let teaser = article.querySelector('.article-info-container');
                    let input = article.querySelector('.article-input');
					let nextdiv = article.nextElementSibling;
                    if (teaser) teaser.style.display = 'none';
                    if (input) {
						input.style.display = 'block';
						input.querySelector('input').value = '';
					}
					if (nextdiv) {
						var inputs = nextdiv.querySelectorAll('input');
						for (var k = 0; k < inputs.length; ++k) {
							inputs[k].value = '';
						}
						nextdiv.querySelector('img').src = rel_prefix + '/images/default-user.png';
					}
                });
            }

			let input = article.querySelector('input.js-article-search');
            input.addEventListener('click', function (e) {
                e.preventDefault();

                let xhr = new XMLHttpRequest();
                xhr.open("GET", rel_prefix + "/profile/articles/all", true);
//                xhr.open("GET", rel_prefix + "/profile/articles/latest", true);
                xhr.responseType = "document";

                // let input = article.querySelector('input');
                let output = article.querySelector('.autocomplete');
                let empty = article.querySelector('.autocomplete-empty');

                xhr.onreadystatechange = function (e) {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        let result = xhr.response.getElementById('autocomplete-output');
						output.innerHTML = result.innerHTML;

						let article_close = output.querySelector('.js-close-article-search');
						if (article_close) {
							article_close.addEventListener('click', function (e) {
								e.preventDefault();
								var oa = document.querySelectorAll('.article-lookup-one .autocomplete');
								for (var i = 0; i < oa.length; ++i) {
									oa[i].innerHTML = '';
									oa[i].classList.remove('active');
								}
							});
						}

						let items = output.querySelectorAll(".item");
						for(let j = 0; j < items.length; j++) {
							let item = items[j];
							item.addEventListener('click', function () {
								clear_search();
								output.classList.remove('active');
								input.value = item.dataset.id;

								let teaser = item.querySelector('.extra-article');
								let div = article.querySelector('.article-teaser');
								div.innerHTML = teaser.innerHTML;
								teaser.classList.add("active");
                                window.drkk_decorate_page_elements(div);

                                let inputDiv = article.querySelector('.article-input');
                                if (inputDiv) inputDiv.style.display = 'none';
                            });
						}

                        if (output.children.length) {
                            empty.classList.remove('active');
                            output.classList.add("active");
                        } else {
                            empty.classList.add('active');
                            output.classList.remove('active');
                        }
                    }
                };

                xhr.send();
            });
        }

		var playlist_buttons = top.querySelectorAll('.js-playlist');
		for (i = 0; i < playlist_buttons.length; ++i) {
			let pb = playlist_buttons[i];
			let id = pb.dataset.id;
			pb.addEventListener('click', function (e) {
				e.preventDefault();
				var track_play = document.querySelector('.js-playlist-' + id + ' .track .play');
				if (track_play)
					track_play.click();
			});
		}

		var artisttracks_buttons = top.querySelectorAll('.js-playartisttracks');
		for (i = 0; i < artisttracks_buttons.length; ++i) {
			let atb = artisttracks_buttons[i];
			atb.addEventListener('click', function (e) {
				e.preventDefault();
				var track_play = document.querySelector('.playlist .track .play');
				if (track_play)
					track_play.click();
			});
		}

        const initTrackDivs = () => {
            var trackdivs = top.querySelectorAll(".playlist .track");
            let playlist = [];
            for (i = 0; i < trackdivs.length; ++i) {
                trackdivs[i].classList.add("qrd");
                let info = trackdivs[i].querySelector(".info");
                if(info && info.dataset.trackurl) {
                    playlist.push({
                        id: info.dataset.trackid,
                        src: info.dataset.trackurl,
                        artist: info.getElementsByClassName("artist")[0].innerHTML,
                        title: info.getElementsByClassName("title")[0].innerHTML
                    });

                    let index = i;
                    let callback = function() {
                        if(player.player.src && player.player.src == playlist[index].src) {
                            player.playPause();
                        } else {
                            player.addToQueue( playlist.slice(index) );
                            player.next();
                        }
                    };

                    let button = trackdivs[i].querySelectorAll('.play');
                    for (var j = 0; j < button.length; ++j) {
                        button[j].onclick = callback;
                    }

                    if(player && player.playing && info.dataset.trackurl == player.player.src) {
                        trackdivs[i].classList.add("now-playing");
                    }
                }
            }

            trackdivs = top.querySelectorAll(".track");
            for (i = 0; i < trackdivs.length; ++i) {
                if(trackdivs[i].classList.contains("qrd"))
                    continue;

                let info = trackdivs[i].querySelector(".info");
                if(info && info.dataset.trackurl) {
                    let queueItem = {
                        id: info.dataset.trackid,
                        src: info.dataset.trackurl,
                        artist: '',
                        title: ''
                    };
                    if(info.getElementsByClassName("artist").length > 0) {
                        queueItem.artist = info.getElementsByClassName("artist")[0].innerHTML;
                    }
                    if(info.getElementsByClassName("title").length > 0) {
                        queueItem.title =info.getElementsByClassName("title")[0].innerHTML;
                    }

                    let callback = function() {
                        if(player.player.src && player.player.src == queueItem.src) {
                            player.playPause();
                        } else {
                            player.addToQueue([ queueItem ]);
                            player.next();
                        }
                    };

                    let button = trackdivs[i].querySelectorAll('.play');

                    for (j = 0; j < button.length; ++j) {
                        button[j].onclick = callback;
                    }

                    if(player && player.playing && info.dataset.trackurl == player.player.src) {
                        trackdivs[i].classList.add("now-playing");
                    }
                }
            }
        }

        // Called on profile page load
        initTrackDivs()

		function uploadFile(file_input, file, response) {
			let xhr = new XMLHttpRequest();
			xhr.open('PUT', response.signedRequest);

			let text_box = file_input.parentElement.nextElementSibling;
			while (text_box.nodeName !== 'INPUT') {
				text_box = text_box.nextElementSibling;
			}
			let url_input = file_input.parentElement.previousElementSibling;
			let button = text_box ? text_box.previousElementSibling : null;

			let progress = document.createElement("progress");
			progress.setAttribute("max", 100);
			progress.value = 0;

			if (text_box) {
				text_box.parentElement.insertBefore(progress, text_box);
				text_box.classList.add("hidden");
			}
			if (button) {
				button.classList.add("hidden");
			}

			xhr.upload.onprogress = function(e) {
				var percent = Math.ceil((e.loaded / e.total) * 100);
				progress.value = percent;
			};

			let reset_form = function() {
				progress.remove();
				if (text_box) {
					text_box.classList.remove("hidden");
					text_box.value = response.fileName;
				}
				if (button) {
					button.classList.remove("hidden");
				}
			};

			xhr.onreadystatechange = function() {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						if (file_input.getAttribute('id').match(/^file-track/) && url_input) {
							xhr = new XMLHttpRequest();
							let params = 'uuid=' + response.uuid;
							xhr.open('POST', rel_prefix + '/track/check_encoding', true);
							xhr.responseType = "json";
							xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

							xhr.onreadystatechange = function() {
								if (xhr.readyState === 4) {
									if (xhr.status === 200) {
										let data = xhr.response;
										if (typeof data === 'string') { // argh, IE
											try {
												data = JSON.parse(data);
											} catch (e) {
												data = {};
											}
										}

										if (data.status === 'ok') {
											remove_errors(file_input.parentElement.parentElement);
											url_input.value = response.url;
										}
										else if (data.msg) {
											response.fileName = '';
											reset_form();
											remove_errors(file_input.parentElement.parentElement);
											var error = { param: 'filename-holder', msg: data.msg };
											add_errors(file_input.form, [error]);
											return;
										}
									}
									reset_form();
								}
							};
							xhr.send(params);
						}
						else {
							var selector = file_input.getAttribute('data-sel');
							if (selector) { // only for images
								var elms = document.querySelectorAll(selector);
								for (var i = 0; i < elms.length; ++i) {
									elms[i].src = response.imageUrl;
								}
							}

							if (file_input.getAttribute('id').match(/^file-/) && url_input) {
								url_input.value = response.url;
							}
							reset_form();
						}
					}
					else {
						reset_form();
					}
				}
			};
			xhr.send(file);
		}

		/**
		 * Get valid S3 url where we can upload the file
		 */
		function getSignedRequest(file_input, file) {
			const xhr = new XMLHttpRequest();
			var save = file_input.getAttribute('id') === 'file-profile-img' ? 'y' : 'n';
			var path = file_input.dataset.path;

			xhr.open('GET', rel_prefix + "/sign-s3/" + path + "?file-name=" + file.name + "&file-type=" + file.type + "&save=" + save);
			xhr.onreadystatechange = function() {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						const response = JSON.parse(xhr.responseText);
						uploadFile(file_input, file, response);
					}
				}
			};
			xhr.send();
		}

		/**
		 * Check if file is valid
		 */
		function validateFile(file_input, file) {
			const xhr = new XMLHttpRequest();
			xhr.open('POST', rel_prefix + '/util/checkfile', true);

			var formData = new FormData();
			formData.append("file", file);
			// Set max size to 10 MB
			formData.append("maxSize", 10*1024*1024);

			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						getSignedRequest(file_input, file)
					}
					else {
						set_message(xhr.response);
					}
				}
			};

			xhr.send(formData);
		}

		var fileimages = document.querySelectorAll(".file-image");
		for (i = 0; i < fileimages.length; ++i) {
			fileimages[i].onchange = function() {
				const file = this.files[0];
				if (!file) {
					return;
				}

				// audio upload elements also have the ".file-image" class.
				// we don't want the below check for audio, so skip files other than images
				if (this.getAttribute('data-path') == 'images') {
					validateFile(this, file);
				}
				else {
					getSignedRequest(this, file);
				}
			};
		}

		var battle = top.querySelector(".battle-container");
		if (battle) {
			var id   = battle.getAttribute('data-id');
			var vote = function(vote) {
				const xhr = new XMLHttpRequest();
				xhr.open('POST', rel_prefix + '/battle/vote/' + id + '/' + vote);
				xhr.responseType = 'document';
				xhr.onreadystatechange = function () {
					if (xhr.readyState === 4 && xhr.status === 200) {
						var left  = xhr.response.querySelector('[data-left]').getAttribute('data-left');
						var right = xhr.response.querySelector('[data-right]').getAttribute('data-right');
						var vote  = xhr.response.querySelector('[data-vote]').getAttribute('data-vote');

						battle.querySelector('.track-left .percentage').style.bottom = left;
						battle.querySelector('.track-left .like-baro-num').innerHTML = left;
						battle.querySelector('.track-left .vote-btn').classList.toggle('active', vote == 'left');

						battle.querySelector('.track-right .percentage').style.bottom = right;
						battle.querySelector('.track-right .like-baro-num').innerHTML = right;
						battle.querySelector('.track-right .vote-btn').classList.toggle('active', vote == 'right');
					}
				};
				xhr.send();
			};

			battle.querySelector(".track-left .vote-btn").addEventListener('click', function (e) {
				vote('left');
			});

			battle.querySelector(".track-right .vote-btn").addEventListener('click', function (e) {
				vote('right');
			});
		}

		var prev = top.querySelector("#js-gallery-previous");
		var next = top.querySelector("#js-gallery-next");
		function gallery(move) {
			var caption = top.querySelector('#js-gallery-caption');
			var index_container = top.querySelector("#js-gallery-index");
			var idx = index_container.textContent - 1;
			var newidx = idx + move;
			var image_divs = top.querySelectorAll('.slide---group div');
			if (image_divs[newidx]) {
				image_divs[idx].classList.remove('active-slide');
				image_divs[newidx].classList.add('active-slide');
				caption.innerHTML = image_divs[newidx].children[0].getAttribute('alt');
				index_container.innerHTML = newidx + 1;
			}
		}
		if (prev && next) {
			prev.addEventListener('click', function (e) {
				e.preventDefault();
				gallery(-1);
			});
			next.addEventListener('click', function (e) {
				e.preventDefault();
				gallery(1);
			});

			function key_event (e) {
				if (e.which == 37) {
					gallery(-1);
				}
				else if (e.which == 39) {
					gallery(1);
				}
			}

			document.addEventListener('keyup', key_event);
			current_keyup = key_event;
		}

		// Handling frontpage slideshow
        var slideIndex = 0;
        function plusSlides(n) {
			if (prev && next) {
				gallery(n);
			}
			else {
				showSlides(slideIndex += n);
			}
        }

        function currentSlide(n) {
            showSlides(slideIndex = n);
        }

        function showSlides(n) {
            var i;
            var slides = top.querySelectorAll(".slide-item");
            var dots = top.querySelectorAll(".dot");

            if(n<0){
                slideIndex = slides.length - 1;
            }else{
                slideIndex = n%slides.length;
            }

            for (i = 0; i < slides.length; i++) {
                slides[i].classList.remove("active");
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].classList.remove("active");
            }

            if (slides[slideIndex])
                slides[slideIndex].classList.add("active");
            if (dots[slideIndex])
                dots[slideIndex].classList.add("active");
        }

        function initSlides() {
            var dots = top.querySelectorAll(".dot");
            for (i = 0; i < dots.length; i++) {
            	let j = i;
                dots[i].addEventListener("click", function(e) {
                	e.preventDefault();
                    currentSlide(j);
					set_hash(0,j);
					update_history();
                });
            }
			click_elem_indexed_from_hash(0, dots);
        }
        initSlides();

        var slideTouch = top.querySelector('#slide-touch');
        if (slideTouch) {
            slideTouch.addEventListener('touchstart', handleTouchStart, false);
            slideTouch.addEventListener('touchmove', handleTouchMove, false);
            slideTouch.addEventListener('touchend', handleTouchEnd, false);
        }

        var xDown = null;
        var yDown = null;
        var xDiff = null;
        var yDiff = null;

        function handleTouchStart(evt) {
            xDown = evt.touches[0].clientX;
            yDown = evt.touches[0].clientY;
        }

        function handleTouchMove(evt) {
            if (!xDown || !yDown) {
                return;
            }

            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;

            xDiff = xDown - xUp;
            yDiff = yDown - yUp;
        };

        function handleTouchEnd(evt) {
            if (!xDiff || !yDiff) {
                return;
            }
            if ((Math.abs(xDiff) > 40 || Math.abs(yDiff) > 40) && Math.abs( xDiff ) > Math.abs( yDiff ) ) { // most significant
                evt.preventDefault();
                if ( xDiff > 0 ) {
                    // left swipe
					plusSlides(1);
                } else {
                    // right swipe
                    plusSlides(-1);
                }
            } else {
                // Just ignore
                if ( yDiff > 0 ) {
                    // up swipe
                } else {
                    // down swipe
                }
            }
            // reset values
            xDown = null;
            yDown = null;
            xDiff = null;
            yDiff = null;
        }

        // Admin profile page - new songs: sort tracks by oldest or newest and filter by genre
        const container = top.querySelector('.admin-track-list-container')
        const sortContainer = top.querySelector('.admin-sort-tracks')
        const filterContainer = top.querySelector('.admin-filter-tracks')

        if (container && sortContainer && filterContainer) {
            const oldestButton = sortContainer.querySelector('.oldest')
            const newestButton = sortContainer.querySelector('.newest')

            const fetchTracks = (base, filter) => {
                const url = `${base}/${filter}/0/20`

                const xhr = new XMLHttpRequest()
                xhr.open("GET", url, true)
                xhr.responseType = "document"
                xhr.setRequestHeader('Content-type', 'text/html')

                xhr.onreadystatechange = function () {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        const responseTracks = xhr.response.querySelector('.track-list-container')
                        container.innerHTML = ""
                        container.appendChild(responseTracks)

                        // Init listeners again after replacing html with newest/oldest content
                        initRadioHandlers()
                        initTrackDivs()
                        initLoadMoreTracks()
                        initTrackForms()
                    }
                };

                xhr.send()
            }

            oldestButton.addEventListener('click', () => {
                if (oldestButton.classList.contains('active')) return

                newestButton.classList.remove('active')
                oldestButton.classList.add('active')

                const base = oldestButton.getAttribute('data-basehref')
                const tracksFilter = filterContainer.dataset.filter

                fetchTracks(base, tracksFilter)
            })

            newestButton.addEventListener('click', () => {
                if (newestButton.classList.contains('active')) return

                oldestButton.classList.remove('active')
                newestButton.classList.add('active')

                const base = newestButton.getAttribute('data-basehref')
                const tracksFilter = filterContainer.dataset.filter

                fetchTracks(base, tracksFilter)
            })

            const genreInputs = filterContainer.querySelectorAll('input')

            for (let i = 0; i < genreInputs.length; i++) {
                const input = genreInputs[i];

                // Select input on page load
                if (filterContainer.dataset.filter === input.value) {
                    input.checked = true
                }

                input.addEventListener('change', function (e) {
                    e.preventDefault();

                    filterContainer.dataset.filter = e.target.value

                    let base = ""

                    if (oldestButton.classList.contains('active')) {
                        base = oldestButton.getAttribute('data-basehref')
                    }
                    if (newestButton.classList.contains('active')) {
                        base = newestButton.getAttribute('data-basehref')
                    }

                    fetchTracks(base, e.target.value)
                })
            }

            const initLoadMoreTracks = () => {
                const tracksPagination = top.querySelectorAll('.admin-tracks-pagination')

                for (let i = 0; i < tracksPagination.length; i++) {
                    const pagination = tracksPagination[i];

                    pagination.addEventListener('click', function(e) {
                        e.preventDefault();

                        const isNext = pagination.classList.contains('next')

                        const offsetValue = top.querySelector('.track-list-container').dataset.offset

                        let offset = 20
                        if (offsetValue) {
                            if (isNext) {
                                offset = parseInt(offsetValue) + 20
                            } else {
                                offset = parseInt(offsetValue) - 20
                            }
                        }

                        const limit  = 20

                        let baseUrl

                        if (oldestButton.classList.contains('active')) {
                            baseUrl = oldestButton.getAttribute('data-basehref')
                        } else {
                            baseUrl = newestButton.getAttribute('data-basehref')
                        }

                        const tracksFilter = filterContainer.dataset.filter

                        const url = `${baseUrl}/${tracksFilter}/${offset}/${limit}`

                        const xhr = new XMLHttpRequest();
                        xhr.open("GET", url, true);
                        xhr.responseType = "document";
                        xhr.setRequestHeader('Content-type', 'text/html');

                        xhr.onreadystatechange = function (e) {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                const responseTracks = xhr.response.querySelector('.track-list-container')
                                const areMoreTracksContainer = responseTracks.querySelector('.are-more-tracks-container')
                                const moreTracksCount = areMoreTracksContainer.querySelector('.more-tracks-count')
                                const currentLeft = moreTracksCount.getAttribute('data-all-rows-count')

                                container.innerHTML = ""
                                container.appendChild(responseTracks)

                                const newCount = parseInt(currentLeft) - offset
                                if (newCount > 0) {
                                    moreTracksCount.innerHTML = newCount
                                } else {
                                    moreTracksCount.innerHTML = 0
                                }

                                // Init listeners again on new tracks added to html
                                initRadioHandlers()
                                initTrackDivs()
                                initTrackForms()
                                initLoadMoreTracks()
                            }
                        };

                        xhr.send();
                    });
                }
            }

            // Called on profile page load
            initLoadMoreTracks()
        }
    };

	document.getElementById('overlay').addEventListener('click', function(e) {
		clear_search();
		var pops = document.querySelectorAll('.popup-window');
		for (var i = 0; i < pops.length; ++i)
			pops[i].classList.add('hidden');
	});

	if (typeof window.history.pushState !== 'undefined') {
		url_prefix = url_prefix.replace(/https?/i, 'https?');
		page_container = document.querySelector('.js-single-page-replace');

		update_history();

		window.onpopstate = function(e) {
			if (e.state && e.state.html){
				replace_content(e.state.html, e.state.title);
			}
		};

		window.drkk_decorate_page_elements(document);
	}

}());
