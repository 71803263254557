// Usage
// document.addEventListener("DOMContentLoaded", function() {
//  	player = new NaïvePlayer('qrd');
// });

if(Math.trunc !== "function") {
	// Quick & dirty polyfill for broken ie11
	Math.trunc = function(x) {
        return x === 0 ? x : x < 0 ? Math.ceil(x) : Math.floor(x);
    };
}

document.NaïvePlayer = function (elem) {
	// DOM elements
	this.parent = elem;
	if(typeof elem !== 'object')
		this.parent = document.getElementById(elem);

	// FIXME add audio tag if it doesn't exist
	this.player = this.parent.getElementsByTagName('audio')[0];
	// this.player.style.display = "none";

	this.progress = {};
	this.progress.selector = this.parent.querySelector('.barMarker');
	this.progress.underlay = this.parent.querySelector('.barUnderlay');
	this.progress.overlay  = this.parent.querySelector('.barCurrent');
	this.progress.limbo    = false;

	this.playpause = document.getElementById('playpause');
	this.nextButton = document.getElementById('playnext');
	this.previousButton = document.getElementById('playprevious');
	this.nextButton.disabled = true; // queue starts out empty
	this.duration = this.parent.getElementsByClassName("duration");
	this.currentTime = this.parent.getElementsByClassName("currentTime");
	this.artist = this.parent.getElementsByClassName("artist");
	this.title = this.parent.getElementsByClassName("title");

	// state vars
	this.playing = false;
	this.queue = [];
	this.index = -1;

	this.addToQueue = (tracks) => {
		if (-1 !== this.index) {
			this.queue = this.queue.slice(0,this.index+1);
		}

		tracks.forEach(
			(track) => this.queue.push(track)
		);
	};

	this._formatTime = function(time) {
		var sec = Math.trunc(time % 60);
		if(sec < 10)
			sec = "0" + sec;
		return(Math.trunc(time / 60) + ":" + sec);
	};
	this._setText = (x, text) => {
		for (let i = 0; i < x.length; i++) {
			if (x[i].innerHTML != text) {
				x[i].innerHTML = text;
				if (typeof window.drkk_decorate_page_elements === "function")
					window.drkk_decorate_page_elements(x[i]);
			}
		}
	};
	this._updateBar = (pct) => {
		if (pct === undefined) {
			var dur = this.player.duration || 100;
			pct = 100 * (this.player.currentTime / dur);
		}
		this.progress.selector.style.left = pct + "%";
		this.progress.overlay.style.width = pct + "%";
	};
	this._updatePlayer = (pct) => {
		this.player.currentTime = pct * this.player.duration / 100;
		this._updateBar(pct);
		this._setText(
			this.currentTime,
			this._formatTime(this.player.currentTime)
		);
		this._setText(
			this.duration,
			this._formatTime(this.player.duration)
		);
	};
	this.playPause = () => {
		if(this.playing) {
			this.player.pause();
		} else {
			this.player.play();
		}
	};
    this.playpause.addEventListener('click', this.playPause);
	this.playTrack = (track) => {
		this.progress.limbo = false;

		this.nextButton.disabled = this.index === this.queue.length - 1;

		// check that we're not trying to start the same track we're currently loading
		if(track && !(!this.playing && this._tracks && this._track.src === track.src)) {
			this._stopPlaying();
			this.playpause.classList.remove('play');
			this.playpause.classList.add('loader');
			this._setText(
				this.duration,
				"--:--"
			);
			this._updateBar(0);
			this.parent.classList.add('active');
			document.querySelector('body').classList.add('player-active');

			track.played = false;
			this._track = track;
			this.player.src = track.src;
			this.player.load();

			this.player.title = '';
			if(track.artist) {
                this._setText(this.artist, track.artist);
                this.player.title = this.artist[0].textContent;
			}
			if(track.title) {
				this._setText(this.title, track.title);
				if(this.player.title) this.player.title += ' - ';
                this.player.title += this.title[0].textContent;
            }

			this.player.play();
		}
	};

	this.next = () => {
		var track;
		if (this.index < this.queue.length - 1) {
			++this.index;
			track = this.queue[this.index];
		}
		this.playTrack(track);
	};
    this.nextButton.addEventListener('click', this.next);

	this.previous = () => {
		var track = this.queue[this.index];
		if (0 < this.index && this.player.currentTime <= 5) {
			--this.index;
			track = this.queue[this.index];
		}
		this.playTrack(track);
	};
    this.previousButton.addEventListener('click', this.previous);

	this.player.addEventListener('durationchange', () => {
		this._updateBar();
        this._setText(
            this.duration,
            this._formatTime(this.player.duration)
        );
	});
	this.player.addEventListener('timeupdate', () => {
		if(!this.progress.limbo)
			this._updateBar();

        this._setText(
            this.currentTime,
            this._formatTime(this.player.currentTime)
        );

        if(this.player.duration) {
        	let played = Math.trunc(100*this.player.currentTime/this.player.duration);
        	if(played > 50 && !this._track.played) {
                this._track.played = true;

                // TODO create custom event and fire that - move this to kk.js
				let xhr = new XMLHttpRequest();
                xhr.open("POST", rel_prefix + '/track/played', true);
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                xhr.onreadystatechange = (e) => {
                    if (xhr.readyState == 4 && xhr.status == 200) {
						// console.log(xhr.response);
                    }
                };
                xhr.send('trackid=' + this._track.id);
            }
		}
	});

	this.player.addEventListener("playing", (e) => {
		this.playpause.classList.remove('audio-paused');
		this.playpause.classList.remove('dr-icon-play');
		this.playpause.classList.remove('play');
		this.playpause.classList.remove('loader');
		this.playpause.classList.add('audio-playing');
		this.playpause.classList.add('dr-icon-pause');
		this.playpause.innerHTML = "";
		this.parent.classList.add('active');
		document.querySelector('body').classList.add('player-active');
		this.playing = true;
        {
            let now_playing = document.querySelectorAll('.now-playing');
            for(let i = 0; i < now_playing.length; ++i) {
                now_playing[i].classList.remove('now-playing');
            }
            let info = document.querySelectorAll("[data-trackid='"+this._track.id+"']");
            for(let i = 0; i < info.length; ++i) {
                let trackdiv = info[i].parentNode;
                while(!trackdiv.classList.contains('track'))
                    trackdiv = trackdiv.parentNode;
                trackdiv.classList.add('now-playing');
            }
        }

    });

	this._stopPlaying = () => {
		this.playpause.classList.remove('audio-playing');
  		this.playpause.classList.remove('dr-icon-pause');
		this.playpause.classList.add('audio-paused');
		this.playpause.classList.add('dr-icon-play');
		this.playpause.classList.add('play');
		this.playpause.classList.remove('loader');
        this.playpause.innerHTML = "";
		{
			this.playing = false;
        	let now_playing = document.querySelectorAll('.now-playing');
        	for(let i = 0; i < now_playing.length; ++i) {
            	now_playing[i].classList.remove('now-playing');
        	}
        }
	};

	this.player.addEventListener("pause", (e) => {
		this._stopPlaying();
	});
	this.player.addEventListener('ended', this.next);

	function getLeft(element) {
		var de = document.documentElement;
		var box = element.getBoundingClientRect();
		var left = box.left + window.pageXOffset - de.clientLeft;
		return left;
	}

	this._mousemove = (x) => {
		var pos = x - getLeft(this.progress.underlay);
		var pct = 100 * pos / this.progress.underlay.getBoundingClientRect().width;
		if (pct > 100) pct = 100;
		if (pct < 0)   pct = 0;
		this._updatePlayer(pct);
	};

	this.progress.underlay.addEventListener("click", (e) => {
		this._mousemove(e.pageX);
	});

	this.progress.selector.addEventListener("touchstart", (e) => {
		this.progress.limbo = true;
		this._mousemove(e.touches[0].clientX);
	}, false);
	this.progress.selector.addEventListener("touchmove", (e) => {
		if (this.progress.limbo) {
			this._mousemove(e.touches[0].clientX);
		}
	}, false);
	this.progress.selector.addEventListener("touchend", (e) => {
		if (this.progress.limbo) {
			this.progress.limbo = false;
			this._mousemove(e.touches[0].clientX);
		}
	}, false);

	this.progress.selector.addEventListener("mousedown", (e) => {
		this.progress.limbo = true;
		this._mousemove(e.pageX);
	});
	document.addEventListener("mouseup", (e) => {
		if (this.progress.limbo) {
			this.progress.limbo = false;
			this._mousemove(e.pageX);
		}
	});
	document.addEventListener("mousemove", (e) => {
		if (this.progress.limbo) {
			this._mousemove(e.pageX);
		}
	});
}
