/*
 * NRK has old urls containing hashes. Since these are only visible
 * client-side and thus never sent to the server, we need to do our
 * redirects here.
 * Patterns:
 *    /#!/Band/<bandname>
 *    /#!/Band/<bandname>/Track/<trackId>
 */

(function () {
  var hash = window.location.hash;
  if (!hash) {
    return;
  }

  var dataArr = hash.split('/');
  if (dataArr.length < 3 || dataArr[0] != '#!' || dataArr[1] != 'Band') {
    return;
  }

  var baseUrl = window.location.origin;

  // It's a band url.
  if (dataArr.length == 3) {
    window.location.replace(baseUrl + '/artist/' + dataArr[2]);
  }

  // It's a track. We need the url based on the track id
  if (dataArr.length == 5 && dataArr[3] == 'Track' && !isNaN(dataArr[4])) {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', baseUrl + '/api/track/' + dataArr[4], true);
    xhr.responseType = 'json';
    xhr.onreadystatechange = function (e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location.replace(baseUrl + '/track/' + dataArr[2] + '/' + xhr.response.internalurl);
      }
    };
    xhr.send();
  }
}());